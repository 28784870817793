html,
body,
#root {
    height: 100vh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
